import React, { useContext, Fragment } from "react";

import { Context } from "../context/Context";
import api from "../api/api";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const ChangeSubscriptionModal = () => {
  const {
    showPlanModal,
    handleClosePlanModal,
    defaultPlans,
    quarterDefaultPlans,
    themeDefaultPlans,
    onChangeStudentData,
    selectedStudent,
    setSelectedStudent,
    updatePlanLoading,
    setUpdatePlanLoading,
    token,
    newRecipientData,
    setNewRecipientData,
    getSubscriptionType,
    themePackTemplates,
  } = useContext(Context);

  const [data, setData] = React.useState({ gift_card: "", id: "" });
  const [isCancelling, setIsCancelling] = React.useState(false);

  React.useEffect(() => {
    setData({
      ...data,
      gift_card: selectedStudent.gift_card,
      id: selectedStudent.id,
    });
  }, [selectedStudent]);

  // console.log(data);

  const updateSubcription = (value) => {
    setSelectedStudent({
      ...selectedStudent,
      plan_selected: value.plan_selected,
      subscription_status: value.subscription_status,
    });
  };

  const editSubscription = () => {
    if (selectedStudent.plan_change !== undefined) {
      if (selectedStudent.plan_change === selectedStudent.plan_selected) {
        alert("You are currently subscribed to this plan");
      } else if (selectedStudent.plan_change === "Choose") {
        alert("Plan cannot be empty");
      } else if (
        selectedStudent.plan_change === "Cancel Subscription" &&
        !isCancelling
      ) {
        setIsCancelling(true);
      } else {
        setUpdatePlanLoading(true);

        api
          .handleUpdateSubscription(token, selectedStudent, updateSubcription)
          .then(() => {
            let updatedData = {
              ...data,
              gift_card: !selectedStudent.plan_change.includes(
                "Weekly Gift Card"
              )
                ? ""
                : "Starbucks",
            };

            return api.handleUpdateStudent(
              token,
              updatedData,
              setSelectedStudent,
              newRecipientData,
              setNewRecipientData,
              getSubscriptionType
            );
          })
          .then(() => {
            setUpdatePlanLoading(false);
            handleCloseModal();
          })
          .finally(() => {
            setUpdatePlanLoading(false);
          });
      }
    } else {
      alert("You are currently subscribed to this plan");
    }
  };

  const listPlans =
    defaultPlans &&
    defaultPlans.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.nickname}
        </option>
      );
    });

  const getPlanOptions = (planList) => {
    return (
      planList &&
      planList.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.nickname}
          </option>
        );
      })
    );
  };

  const getPlanChoices = () => {
    const subscriptionType = selectedStudent.subscription_type;
    let planOptions, planList;

    if (subscriptionType === "quarter") {
      planList = quarterDefaultPlans;
    } else if (subscriptionType === "theme") {
      // planList = themeDefaultPlans.reduce((result, plan) => {
      //   let passed = themePackTemplates.filter(template => template.theme_name === plan.id)
      //   if (passed.length) {
      //     result.push(plan);
      //   }
      //   return result;
      // }, []);

      planList = themeDefaultPlans;
    } else {
      planList = defaultPlans;
    }

    planOptions = getPlanOptions(planList);

    return planList ? (
      planOptions
    ) : (
      <option value="">No Plans Available</option>
    );
  };

  const getCurrentPlanMetaData = () => {
    const subscriptionType = selectedStudent.subscription_type;
    let planList;

    if (subscriptionType === "quarter") {
      planList = quarterDefaultPlans;
    } else if (subscriptionType === "theme") {
      planList = themeDefaultPlans;
    } else {
      planList = defaultPlans;
    }

    return (
      planList &&
      planList.map((item, i) => {
        return (selectedStudent.plan_change === undefined
          ? selectedStudent.plan_selected
          : selectedStudent.plan_change) === item.id ? (
          <Fragment key={i}>
            <p className="text-price">
              <s>{item.metadata.previous_price}</s> {item.metadata.text_price}
            </p>
            <p className="note c-grayish-navy">{item.metadata.plan_desc}</p>
          </Fragment>
        ) : null;
      })
    );
  };

  const handleCloseModal = () => {
    handleClosePlanModal();
    setIsCancelling(false);
  };

  return (
    <Modal
      className="c2c-modal"
      show={showPlanModal}
      onHide={handleCloseModal}
      centered
      backdrop={"static"}
    >
      <div className="btn-close-modal" onClick={handleCloseModal}></div>
      <div className="subscription-holder">
        <div className="form-group">
          <h3 className="info-title t-h1 c-grayish-navy">
            {!isCancelling
              ? `Update Subscription for ${selectedStudent.first_name}`
              : "Confirmation"}
          </h3>
          <h4>Current Plan: {selectedStudent.plan_selected}</h4>

          {!isCancelling && (
            <>
              <h5>Change to : </h5>
              <div className="dropdown-holder">
                <div className="dropdown-holder">
                  <select
                    name="plan_change"
                    value={
                      selectedStudent.plan_change === undefined
                        ? selectedStudent.plan_selected
                        : selectedStudent.plan_change
                    }
                    onChange={onChangeStudentData}
                  >
                    <option defaultValue>Choose</option>
                    <option value="Cancel Subscription">
                      Cancel Subscription
                    </option>
                    {getPlanChoices()}
                  </select>
                  {getCurrentPlanMetaData()}
                </div>
              </div>
            </>
          )}

          {isCancelling && (
            <p>
              Please note: Cancellation will stop cards immediately. If you are
              looking to cancel after your current plan ends, please wait until
              your last card sends to cancel.
            </p>
          )}
        </div>
      </div>

      {isCancelling && (
        <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
          <button
            className="btn-teal c-white card-h card-h-3 d-flex"
            onClick={updatePlanLoading ? null : editSubscription}
            disabled={updatePlanLoading}
          >
            {updatePlanLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
            {updatePlanLoading && <span>Please wait</span>}
            {!updatePlanLoading && <span>Cancel Subscription</span>}
          </button>

          <button
            className="btn-white c-purple border-purple card-h card-h-3 d-flex"
            onClick={() => setIsCancelling(false)}
            disabled={updatePlanLoading}
          >
            {updatePlanLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
            {updatePlanLoading && <span>Please wait</span>}
            {!updatePlanLoading && <span>Go Back</span>}
          </button>
        </div>
      )}

      <div className="btn-holder">
        {!isCancelling && (
          <button
            className="btn-teal c-white card-h card-h-3 d-flex"
            onClick={updatePlanLoading ? null : editSubscription}
            disabled={updatePlanLoading}
          >
            {updatePlanLoading && (
              <Spinner
                className="mr-2"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
            {updatePlanLoading && <span>Please wait</span>}
            {!updatePlanLoading && <span>Update</span>}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ChangeSubscriptionModal;
